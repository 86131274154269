//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//CD Change overwrites the fonts
@font-face {
  font-family: "CeraPro-Light";
  src: url("../../fonts/Webfonts/Cera-Pro-Light.eot");
  src: url("../../fonts/Webfonts/Cera-Pro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Webfonts/Cera-Pro-Light.woff2") format("woff2"),
    url("../../fonts/Webfonts/Cera-Pro-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraPro-Regular";
  src: url("../../fonts/Webfonts/Cera-Pro-Regular.eot");
  src: url("../../fonts/Webfonts/Cera-Pro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Webfonts/Cera-Pro-Regular.woff2") format("woff2"),
    url("../../fonts/Webfonts/Cera-Pro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraPro-Medium";
  src: url("../../fonts/Webfonts/Cera-Pro-Medium.eot");
  src: url("../../fonts/Webfonts/Cera-Pro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Webfonts/Cera-Pro-Medium.woff2") format("woff2"),
    url("../../fonts/Webfonts/Cera-Pro-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraPro-Bold";
  src: url("../../fonts/Webfonts/Cera-Pro-Bold.eot");
  src: url("../../fonts/Webfonts/Cera-Pro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/Webfonts/Cera-Pro-Bold.woff2") format("woff2"),
    url("../../fonts/Webfonts/Cera-Pro-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Poppins Thin (100) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

/* Poppins Extra-Light (200) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

/* Poppins Light (300) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

/* Poppins Regular (400) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

/* Poppins Medium (500) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

/* Poppins Semi-Bold (600) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

/* Poppins Bold (700) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

/* Poppins Extra-Bold (800) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");

/* Poppins Black (900) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");

$font-family-sans-serif: "Poppins", Inter, Helvetica, "sans-serif" !default;

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
//$primary: #009ef7;
$primary: rgba(0, 81, 210);

//$primary-active: #0095e8;
$primary-active: #0051d2;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
//$success: #50cd89;
$success: #008ea4;
//$success:rgba(153, 182, 0);

//$success-active: #47be7d;
$success-active: #00a494;

$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
//$info: #7239ea;
$info: rgba(103, 32, 255);
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: rgba(181, 40, 0);
//$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
//$warning: #ffc700;
$warning: rgba(255, 184, 0);
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
