@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");

h1 {
  font-family: "DM Serif Display", serif;
}

.app-header-menu .menu .menu-item .menu-link .menu-title {
  font-size: 1.25rem;
  letter-spacing: -0.02em;
}

.card-rounded {
  border-radius: 2.35rem !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .1), 0px 8px 16px rgba(0, 0, 0, .1) !important;
  border:var(--bs-card-border-width) solid var(--bs-card-border-color) !important;
  border-color: #9b9b9b  !important;
}

.rounded {
  border-radius: 2.35rem !important;
}

.notice{
  border-radius: 2.35rem !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .1), 0px 8px 16px rgba(0, 0, 0, .1) !important;
}


.menu-rounded .menu-link,
.btn {
  border-radius: 30px;
  
}

.form-control {
  border-radius: 1.25rem;
}

.input-group.input-group-solid .form-control
,.form-select.form-select-solid
,.css-t3ipsp-control, .css-13cymwt-control
,.input-group-text{
  border-color: #9b9b9b !important;
  border: 1px solid var(--bs-gray-300);
}


.form-control-lg {
  font-size: 1.5rem;
}

// .btn.btn-active-color-primary.active {
//   color: var(--bs-text-primary);
//   background-color: var(--bs-secondary) !important;
// }

.modal-content {
  overflow: hidden;
}

.bg-custom-1 {
  //background-color: #ccdcf6 !important;
  background-color: #f0f6f9;
}

.ms1 {
  background-color: white;
}
.ms-1 .active {
  background-color: black;
}

.modal-content {
  border-radius: 2rem;
}

.modal-header {
  border-bottom: unset;
}

.btn.btn-outline.btn-outline-dashed {
  border-width: 2px;
  border-style: solid;
}

// .btn.btn-outline-dashed {
//   border: 1px solid transparent;
// }

.btn-check:checked + .btn.btn-outline.btn-outline-dashed,
.btn-check:active + .btn.btn-outline.btn-outline-dashed,
.btn.btn-outline.btn-outline-dashed:focus:not(.btn-active),
.btn.btn-outline.btn-outline-dashed:hover:not(.btn-active),
.btn.btn-outline.btn-outline-dashed:active:not(.btn-active),
.btn.btn-outline.btn-outline-dashed.active,
.btn.btn-outline.btn-outline-dashed.show,
.show > .btn.btn-outline.btn-outline-dashed {
  border-color: var(--bs-dark);

  // content: "";
  // display: inline-block;
  // background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) " fill="green"></path></g></svg>');
  // background-size: cover;
  // width: 24px; /* Width of the SVG icon */
  // height: 24px; /* Height of the SVG icon */
}

.btn-check:checked + .btn.btn-outline.btn-outline-dashed::after,
.btn-check:active + .btn.btn-outline.btn-outline-dashed::after,
.btn.btn-outline.btn-outline-dashed:focus:not(.btn-active)::after,
//.btn.btn-outline.btn-outline-dashed:hover:not(.btn-active)::after,
.btn.btn-outline.btn-outline-dashed:active:not(.btn-active)::after,
.btn.btn-outline.btn-outline-dashed.active::after,
.btn.btn-outline.btn-outline-dashed.show::after,
.show > .btn.btn-outline.btn-outline-dashed::after {
  content: "";
  display: inline-block;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"%3E%3Cg stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"%3E%3Cpolygon points="0 0 24 0 24 24 0 24"%3E%3C/polygon%3E%3Cpath d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) " fill="green"%3E%3C/path%3E%3C/g%3E%3C/svg%3E');
  background-size: cover;
  width: 24px;
  height: 24px;
  position: absolute;
}

.app-header-menu .menu .menu-item .menu-link.active,
.app-header-menu .menu .menu-item .menu-link:hover {
  background-color: transparent;
  border: 2px solid black;
}

.app-header-menu .menu .menu-item .menu-link {
  background-color: transparent;
}

.app-header-menu .menu .menu-item .menu-link.active .menu-title {
  color: black;
}

.app-header-menu .menu .menu-item .menu-link .menu-title {
  color: black;
}

//search lookup
.css-t3ipsp-control,
.css-13cymwt-control {
  border-radius: 1.25rem !important;
  background-color: var(--bs-gray-100) !important;
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  font-size: 1.1rem;
  //padding: 0.775rem 3rem 0.775rem 1rem;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  line-height: 1.1;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
}

.css-1dimb5e-singleValue {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700) !important;
}


.app-header{
  background-color: var(--bs-app-header-base-bg-color);
    border-bottom: var(--bs-app-header-base-border-bottom);
    box-shadow: var(--bs-app-header-base-box-shadow);
    transition: none;
    display: block;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 1 !important;
}

.modal-backdrop-confirmation {
  opacity: 0.5;
  z-index: 1055;
}


.arrowless-select {
  /* Remove default browser and Bootstrap arrow */
  -webkit-appearance: none; /* for Chrome, Safari, and Opera */
  -moz-appearance: none;    /* for Firefox */
  appearance: none;
  padding: 0.775rem 1rem;
  /* Remove background image (the down caret) that Bootstrap applies */
  background-image: none !important;

  /* Optionally hide the IE/Edge dropdown arrow */
}
.arrowless-select::-ms-expand {
  display: none;
}