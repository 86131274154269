//
// Reboot
//

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $app-general-root-font-size-desktop !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $app-general-root-font-size-tablet !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $app-general-root-font-size-mobile !important;
  }
}

body {
  display: flex;
  flex-direction: column;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

canvas {
  user-select: none;
}

// Angular integration
router-outlet {
  display: none;
}

// Layouts
body,
.app-default {
  background: var(--#{$prefix}app-bg-color);
  // background-image: url(../../../../../../../public/media/backgrounds/background1.svg);
  // background-size: cover;
  //background-repeat: no-repeat;
//background: rgb(63,94,251);
//background: linear-gradient(90deg, rgba(63,94,251,1) 19%, rgba(252,70,70,1) 100%);
// background: rgb(0,81,210);
// background: linear-gradient(180deg, rgba(0,81,210,1) 0%, rgba(0,0,0,1) 30%);
// background-repeat: no-repeat;

}

.app-blank {
  background-color: var(--#{$prefix}app-blank-bg-color);
}

// Reset CSS3 Transitions for child elements
[data-kt-app-reset-transition='true'] * {
  transition: none !important;
}
